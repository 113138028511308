function VocoViewManager(ioc, device, chi, options) {
    this.options = options || {}
    this.packageQueue = []
    this.device = device
    this.ioc = ioc
    this.helpers = chi.helpers
    this.moduleFactory = chi.moduleFactory
	this.isMessageVisible = this.options.isMessageVisible || chi.isMessageVisible
	this.resolveThreeRadicalConfig = chi.resolveThreeRadicalConfig
	this.resolveThreeRadicalSubPackage = chi.resolveThreeRadicalSubPackage
	this.showMessageToUserFn = this.options.showMessageToUserFn
	this.nameToLocation = options.nameToLocation || []
	this.simpleMessageHandler = options.simpleMessageHandler
	this.voucherMessageHandler = options.voucherMessageHandler
	this.winMessageHandler = options.winMessageHandler
	this.unlockNavigateToIds = []
	this.unlockOpenModalTacticsIds = []
	this.unlockInstantMessagesIds = []
	this.unlockInstantMessagesQueue = []
	this.unlockRedeemMessageIds = []
	this.messageToMount = {}
	if (!this.showMessageToUserFn) {
		this.showMessageToUserFn = function (message) {
			alert(message.message || message)
			
			return Promise.resolve(true)
		}
	}
	
	this.createMainScript = chi.createMainScript
	this.loadScript = chi.loadScript
	this.getPackageGuid = chi.getPackageGuid
	this.getPackageName = chi.getPackageName
	this.getPackageUrl = chi.getPackageUrl
	this.loadPackage = chi.loadPackage
	this.getSchemePackageName = chi.getSchemePackageName
	this.messageLookupAndLoadPackage = chi.lookupAndLoad
	this.createMainPackageScript = chi.createMainScript
}

VocoViewManager.prototype.navigateTo = function (id, options, parameters = {}) {
	options = options || {}
	let Device = this.device
	
	let message
	
	if (typeof id == "string" && id.startsWith("https://")) {
		console.log("NavigateTo:", "window.location", id)
		setTimeout(function(){
			window.location = id
		},100)
		
		return Promise.resolve(true)
	}
	
	if (typeof id == "string" && id.startsWith("/")) {
		console.log("NavigateTo:", "window.location", id)
		setTimeout(function(){
			window.location = id
		},100)
		
		return Promise.resolve(true)
	}
	
	if (typeof id == "string" && id.startsWith("./")) {
		console.log("NavigateTo:", "window.location", id.substr(2))
		setTimeout(function(){
			window.location = id.substr(2)
		},100)
		
		return Promise.resolve(true)
	}
	
	if (typeof id == "string" && id.startsWith("#") && id.split(":").length == 2) {
		let [elId, tacticName] = id.split(":")
		console.log("NavigateTo:", "mountOne", "selector:", elId, "tacticName:", tacticName)
		return this.mountOne({ name : tacticName, selector : elId })	
	}
	
	if (typeof id == "string" && id.startsWith("#") && options.internalNavigation) {
		let elId = id.substr(1)
		let el = document.getElementById(elId)
		if (!el) {
			throw new Error(`ElementID ${elId} not found in DOM`)
		}
		
		if (!el.threeRadicalNavigation?.goTo) {
			throw new Error(`ElementID ${elId} does not supply an internal navigation API`)
		}
		
		console.log("NavigateTo:", "internalNavigation", "selector:", elId, "parameters:", JSON.stringify(parameters))
		return el.threeRadicalNavigation.goTo(id, options, parameters)
	}
	
	if (typeof id == "object") {
		message = id
	} else {
		message = Device.allMessages
			.filter(this.isMessageVisible)
			.filter(matchTacticName(id))[0]
	}
	
	let openModal
	let closeModal
	
	if (this.options.modal) {
		openModal = options.openModal || this.options.modal.open	
		closeModal = options.closeModal || this.options.modal.close
	} else {
		openModal = (options.openModal || this.options.openModal)
		closeModal = (options.closeModal || this.options.closeModal)
	}
	
	if (!openModal) {
		throw new Error("No Modal Dialog Available")
	}
	
	if (!message) {
		console.warn("NavigateTo:", "Not Found", id)
		return
	}
	
	if (!this.isMessageVisible(message)) {
		console.warn("NavigateTo:", "Not Visible", id)
		return
	}
	
	let messageName = (message.name || "").split("::")[0]
	
	let nameToLocation = this.nameToLocation.filter(nameLocation => nameLocation.name == messageName)[0]
	
	if (nameToLocation && nameToLocation.location) {
		if (nameToLocation.location.startsWith("./")) {
			console.log("NavigateTo:", "nameToLocation",  nameToLocation.location.substr(2))
			setTimeout(function (){
				window.location = nameToLocation.location.substr(2)
			}, 100)
			
			return Promise.resolve(true)
		} else {
			console.log("NavigateTo:", "nameToLocation",  nameToLocation.location)
			setTimeout(function (){
				window.location = nameToLocation.location
			}, 100)
			
			return Promise.resolve(true)
		}
	}
	
	
	let el = openModal(message)
	let self = this
	let device = this.device
	
	return self.messageLookupAndLoadPackage(message)
		.then(function (result){
			let gameNavigation = {
				prev : function () {
					device.unlock("WM.prev", message.systemId)
					return closeModal(message)	
				},
				goTo : function (openId) {
					return closeModal(message)
						.then(function (){
							device.unlock("WM.goTo", { systemId : message.systemId, to: openId})
							return self.navigateTo(openId)
						})
				},
				next : function () {
					device.unlock("WM.next", message.systemId)
					return closeModal(message)
				}
			}
			
			console.log("NavigateTo:", "openModal", result, message.systemId )
			
			if (!self.messageToMount[message.systemId]) {
				self.messageToMount[message.systemId] = [];
			}
			
			self.messageToMount[message.systemId].push({ el : el, navigation : gameNavigation});
				
			return Promise.resolve(window[result].default(
				self.moduleFactory(gameNavigation, self.showMessageToUserFn, message, result),
				self.resolveThreeRadicalConfig(message, self.options),
				el,
				message
			)).then(function (val){
				return Promise.resolve(Device.read(message))
					.then(function(){
						return val
					})
			})
		})
		
}

VocoViewManager.prototype.mountCategory = function(params, sdm) {
	if(!params.category){
		return 
	}
	let self = this
	let device = this.device
	let launchGame = function(message, index) {
    	console.log("mountCategory:", "launchGame", params.category, params.selector, message.systemId)
    	let paramsElem = params.el
    	let listOfEls = (paramsElem && typeof paramsElem.forEach == "function") ? paramsElem :  (paramsElem ? [paramsElem] : '')
    	let targetElements = listOfEls || document.querySelectorAll(params.selector)
    	if (!targetElements[index]) {
			console.warn("mountCategory:", "selectorNotFound", params.category, params.selector, message.systemId)
			return
		}
		targetElements[index].className += " voco-category-mount"
		
		if(self.isMessageVisible(message)) {
			if(sdm == "cssClass"){
				targetElements[index].className += " vvm-tactic-available"
			}
			
			self.messageLookupAndLoadPackage(message).then(function(result) {
				let containerElement = null
				if (params.containerSelector) {
					containerElement = document.querySelector(params.containerSelector)	
				}
				
				if (!containerElement) {
    				console.warn("mountCategory:", "containerNotFound", params.category, params.selector, message.systemId, params.containerSelector)
				}
				
				if (params.containerDisplay) {
					containerElement.style.display = params.containerDisplay	
				}
				
				let gameNavigation = getGameNavigation(containerElement, targetElements[index], message, self)
				
				console.log("mountCategory:", "mount", result, params.category, params.selector, message.systemId, "index : ", index)
				
				if (!self.messageToMount[message.systemId]) {
					self.messageToMount[message.systemId] = [];
				}
				
				self.messageToMount[message.systemId].push({ el : targetElements[index], navigation : gameNavigation});
				
				return Promise.resolve(window[result].default(
					self.moduleFactory(gameNavigation, self.showMessageToUserFn, message, result),
					self.resolveThreeRadicalConfig(message, self.options),
					targetElements[index],
					message
				)).then(() => {
					return Promise.resolve(self.device.unlock("WM.Mount", message.systemId, message.ATTRIBUTES.origin))
				})
			})
		} else {
			if(sdm == "cssClass"){
				targetElements[index].className += " vvm-tactic-unavailable"
			}
			console.warn("mountCategory:", "notVisible",  message.systemId, params.selector)
		}
    }
    
    let matchTactic = matchTacticCategory(params.category)
    
    let onMessages = function(messages) {
		let messagesLocal = messages
    		.filter(self.isMessageVisible)
			.filter(matchTactic)
		
	    if(messagesLocal.length > 0) {
    		console.log("mountCategory:", "arrived", params.name)
	    	self.device.off('messages', onMessages)
    		messagesLocal.forEach((message, index) => {
	    		launchGame(message, index)
	    	})
    	}
    	
    	return messages
    }    
    
    let messages = this.device.allMessages
    	.filter(this.isMessageVisible)
    	.filter(matchTactic)
    	
    if(messages.length == 0) {
    	console.log("mountCategory:", "waitForMessage", params.name)
    	this.device.on('messages', onMessages)
    } else {
    	messages.forEach((message, index) => {
    		launchGame(message, index)
    	})
    }
}

VocoViewManager.prototype.mountOne = function(params, sdm) {
	if(!params.name){
		return 
	}
	
	let self = this
	let device = this.device
    let launchGame = function(message) {
    	console.log("mountOne:", "launchGame", params.name, params.selector, message.systemId)
    	
    	let targetElement = params.el || document.querySelector(params.selector)
    	
    	if (!targetElement) {
    		console.warn("mountOne:", "selectorNotFound", params.name, params.selector, message.systemId)
			return
		}
    	
    	targetElement.className += " voco-mount"
		
		if(self.isMessageVisible(message)) {

			if(sdm == "cssClass"){
				targetElement.className += " vvm-tactic-available"
			}
			
			self.messageLookupAndLoadPackage(message).then(function(result) {
				
				let containerElement = null
				
				if (params.containerSelector) {
					containerElement = document.querySelector(params.containerSelector)	
				}
				
				if (!containerElement) {
    				console.warn("mountOne:", "containerNotFound", params.name, params.selector, message.systemId, params.containerSelector)
				}
				
				if (params.containerDisplay) {
					containerElement.style.display = params.containerDisplay	
				}
				let gameNavigation = getGameNavigation(containerElement, targetElement, message, self)
				console.log("mountOne:", "mount", result, params.name, params.selector, message.systemId)
				
				if (!self.messageToMount[message.systemId]) {
					self.messageToMount[message.systemId] = [];
				}
				
				self.messageToMount[message.systemId].push({ el : targetElement, navigation : gameNavigation});
				
				return Promise.resolve(window[result].default(
					self.moduleFactory(gameNavigation, self.showMessageToUserFn, message, result),
					self.resolveThreeRadicalConfig(message, self.options),
					targetElement,
					message
				)).then(() => {
					launchIntro(message)
					return Promise.resolve(self.device.unlock("WM.Mount", message.systemId, message.ATTRIBUTES.origin))
				})
			})
		} else {
			
			if(sdm == "cssClass"){
				targetElement.className += " vvm-tactic-unavailable"
			}
			
			console.warn("mountOne:", "notVisible",  message.systemId, params.selector)
		}
    }
    let launchIntro=function(message){
        device.trigger('tactic.modal', message)
    }
    
    let matchTactic = matchTacticName(params.name)
    
    let onMessages = function(messages) {
		let messagesLocal = messages
    		.filter(self.isMessageVisible)
			.filter(matchTactic)
			
	    if(messagesLocal.length > 0) {
    		console.log("mountOne:", "arrived", params.name)
	    	self.device.off('messages', onMessages)
    		launchGame(messagesLocal[0])
    	}
    	
    	return messages
    }    
    
    let messages = this.device.allMessages
    	.filter(this.isMessageVisible)
    	.filter(matchTactic)
    
    if(messages.length == 0) {
    	console.log("mountOne:", "waitForMessage", params.name)
    	this.device.on('messages', onMessages)
    } else {
    	launchGame(messages[0])
    }
}

VocoViewManager.prototype.onClickNavigateToTactic = function (params, sdm) {
	let self = this
	let targetElement = document.querySelector(params.selector)

	if (!targetElement) {
		return
	}
	
	let display = params.display
	if (!display && targetElement.style.display != "none") {
		display = targetElement.style.display
	} else {
		display = "block"
	}
	
	let matchTactic = matchTacticName(params.name)
	
	let messages = this.device.allMessages
		.filter(matchTactic)
		.filter(self.isMessageVisible.bind(self))
	
	if (messages.length) {
		attachClickListener(messages[0])
		if(sdm == "cssClass"){
			targetElement.className += " vvm-tactic-available"
		}
	} else {
		if(sdm == "cssClass"){
			targetElement.className += " vvm-tactic-unavailable"
		} else {
			targetElement.style.display = "none"
			self.device.on("messages", onMessages)
		}
	}
	
	function attachClickListener(message, unhide) {
		if (unhide) {
			targetElement.style.display = display		
		}
		
		targetElement.vocoAttachedTo = message.messageId
		
		targetElement.addEventListener("click", function (e) {
			self.navigateTo(message)	
		})
	}
	
	function onMessages (eventMessages) {
		let messages = eventMessages
			.filter(matchTactic)
			.filter(self.isMessageVisible.bind(self))
		
		if (messages.length && messages[0].messageId != targetElement.vocoAttachedTo) {
			attachClickListener(messages[0], true)
			console.log("MESSAGE SDM ", sdm)
			if(sdm == "cssClass"){
				targetElement.className += " vvm-tactic-available"
			}
		} else if (!messages.length) {
			if(sdm == "cssClass"){
				targetElement.className += " vvm-tactic-unavailable"
			} else {
				targetElement.style.display = "none"
			}
		}
		
		return eventMessages
	}
}

VocoViewManager.prototype.onClickUnlock = function (params) {
	let self = this
	let targetElement = document.querySelector(params.selector)
	
	if (!targetElement) {
		return
	}
	
	targetElement.addEventListener("click", function (e) {
		console.log('Unlock : ', params.unlock)
		self.device.unlock(params.unlock, params.packet)
	})
}

VocoViewManager.prototype.unlockSetVariable = function (packet) {
	let changed = false
	let device = this.device

    const packetResponse = packet && packet.response

    if (!packetResponse) {
        return
    }

    let pending = _.get(device, "user.response.all", [])
    let ids = pending.map(r => r.id)

    if (!(packetResponse.all || []).length) {
        return
    }

    return Promise.all(
        (packetResponse.all || [])
            .filter(r => (r.fn || {}).name === "Set Variable")
            .map(resp => {
                var params = ((resp.fn || {}).parameters || "").split(",")

                if (params.length <= 1) {
                    return Promise.resolve(null)
                }

                return Promise.resolve()
                    .then(() => _.set(device.variables, params[0], params[1]))
                    .then(() => device.updateVariables())
                    .then(() => device.acknowledge(resp.id))
                    .then(() => (packetResponse.all = (packetResponse.all || []).filter(r => r.id !== resp.id)))
                    .catch(() => null)
            })
    )
        .then(() => device.updateVariables())
        .then(() => {
            !(packetResponse.all || []).forEach(function(resp) {
                if (ids.indexOf(resp.id) < 0) {
                    pending.push(resp)
                    changed = true
                }
            })

            if (changed) {
                _.set(device, "user.response.all", pending)
            }
        })
        .catch(() => null)
}

VocoViewManager.prototype.unlockOpenModalTactics = function (gtpPacket) {
	let responseAll = ((gtpPacket || {}).response || {}).all || []
	let unlockOpenModalTacticsIds = this.unlockOpenModalTacticsIds
	
	let openTactics = responseAll
		.filter(resp => resp.fn && resp.fn.name && resp.fn.name == "OpenTactic")
		.filter(resp => unlockOpenModalTacticsIds.indexOf(resp.id) < 0)
		.filter(resp => !resp.fn.processed)
		
	let openTactic = null
	let message = null
	
	/* Find an availaible and visible tactic for the openTactic command */
	
	for (openTactic of openTactics) {
		let systemId = openTactic.fn.parameters.split(",")[0]
		
		message = this.device.allMessages
			.filter(this.isMessageVisible)
            .filter(msg => ((getThreeRadical(msg) || {}).display || {}).displayMode != "fullScreen")
			.filter(msg => msg.systemId == systemId)[0]
		
		if (message) {
			break
		}
	}
	
	if (!message) {
		return
	}
	
	console.log("OpenTactic:", openTactic.id)
	
	openTactic.fn.processed = true
	this.unlockOpenModalTacticsIds.push(openTactic.id)
	this.device.acknowledge(openTactic)
	this.navigateTo(message)
}

/* Note this function does not physically redeem a message just react to a redeemed message.*/

VocoViewManager.prototype.redeemMessage = function (systemId) {
	if (this.messageToMount[systemId]) {
		this.messageToMount[systemId].forEach(function (elNav) {
			if (typeof CustomEvent == "function") {
				let redeemEvent = new CustomEvent("redeemed")
				elNav.el.dispatchEvent(redeemEvent)
			}
			elNav.navigation.next()
		})
	}
}

VocoViewManager.prototype.unlockRedeemMessage = function (gtpPacket) {
	let responseAll = ((gtpPacket || {}).response || {}).all || []
	let unlockRedeemIds = this.unlockRedeemMessageIds || [];
	
	let redeemRs = responseAll
		.filter(resp => resp.redeem)
		.filter(resp => unlockRedeemIds.indexOf(resp.id) < 0)
		.filter(resp => !resp.redeem.processed)
	
	for (let redeemMessage of redeemRs) {
		redeemMessage.redeem.processed = true
		this.device.acknowledge(redeemMessage)
		this.unlockRedeemMessageIds.push(redeemMessage.id)
		this.redeemMessage(redeemMessage.redeem.id)
	}
}

VocoViewManager.prototype.unlockNavigateTo = function (gtpPacket) {
	let responseAll = ((gtpPacket || {}).response || {}).all || []
	let navigateTo = null
	let destination = null
	let unlockNavigateToIds = this.unlockNavigateToIds
	let navigateTos = responseAll
		.filter(resp => resp.fn && resp.fn.name && resp.fn.name == "NavigateTo")
		.filter(resp => unlockNavigateToIds.indexOf(resp.id) < 0)
		.filter(resp => !resp.fn.processed)
	
	
	for (navigateTo of navigateTos) {
		let dest = navigateTo.fn.parameters
		
		if (dest.match(/^\d+$/)) {
			destination = this.device.allMessages
				.filter(this.isMessageVisible)
				.filter(msg => msg.systemId == dest)[0]
			
			if (destination) {
				break;	
			}
		} else if (dest.startsWith("/") || dest.startsWith("https://") || dest.startsWith("./")) {
			destination = dest
		} else {
			destination = this.device.allMessages
				.filter(this.isMessageVisible)
				.filter(matchTacticName(dest))[0]
		}
	}
	
	if (destination) {
		console.log("NavigateTo:", typeof destination == "object" ? destination.systemId : destination)
		navigateTo.fn.processed = true
		this.device.acknowledge(navigateTo)
		this.unlockNavigateToIds.push(navigateTo)
		this.navigateTo(destination)
	}
}

VocoViewManager.prototype.unlockInstantMessage = async function (gtpPacket) {
	let responseAll = ((gtpPacket || {}).response || {}).all || []
	let device = this.device
	let ioc = this.ioc
	let that = this
	let unlockInstantMessagesQueue = this.unlockInstantMessagesQueue
	let unlockInstantMessagesIds = this.unlockInstantMessagesIds
	
	let instantMessages = responseAll
		.filter(resp => !resp.badge && resp.message)
		.filter(resp => unlockInstantMessagesQueue.map(im => im.id).indexOf(resp.id) < 0)
		.filter(resp => unlockInstantMessagesIds.indexOf(resp.id) < 0)
	
	if(instantMessages.length === 0) return;
	
	this.unlockInstantMessagesIds = this.unlockInstantMessagesIds.concat(instantMessages.map(im => im.id))
	
	while(this.unlockInstantMessagesQueue.length > 0) {
		await timeout(10000);
	}
	
	instantMessages = instantMessages
		.filter(resp => unlockInstantMessagesQueue.map(im => im.id).indexOf(resp.id) < 0)
		.filter(resp => unlockInstantMessagesIds.indexOf(resp.id) < 0)
	
	if(instantMessages.length === 0) return;
	this.unlockInstantMessagesQueue = this.unlockInstantMessagesQueue.concat(instantMessages)
	
	for(let i = instantMessages.length-1; i >= 0; i--) { 
		instantMessages[i].buttons = [{
			caption: instantMessages[i].caption	
		}]
		
		instantMessages[i].buttons[0].click = function() {
			device.acknowledge(instantMessages[i])
			updateInstantMessageQueue.apply(that, [instantMessages[i]])
			if(i < instantMessages.length-1) {
				instantMessages[i+1].fct()
			} else {
				ioc.run("3r/chi/systemAlertClose")
			}
		}
		
		instantMessages[i].fct = this.showMessageToUserFn.bind(this, instantMessages[i])
	}
	
	instantMessages[0].fct()
	
	function timeout(ms) {
	    return new Promise(resolve => setTimeout(resolve, ms));
	}
	
	function updateInstantMessageQueue(im) {
		this.unlockInstantMessagesQueue = this.unlockInstantMessagesQueue.filter(uim => uim.id !== im.id)
	}
}

VocoViewManager.prototype.mountFromConfig = async function (config) {
	let that = this
	if (!config) {
		return
	}

	if (config.html) {
		await Promise.allSettled((config.html.forUrl || [])
			.filter(matchCurrentUrl)
			.map(function(cfg) {
				return that.mountHtmlFragment(cfg)
			}))
	}
		
	if (config.onClick) {
		let onClickNavigateTactics = (config.onClick.navigateToTactic || []).filter(
			navigateTactic => matchCurrentUrl(navigateTactic) && navigateTactic.name !== undefined && navigateTactic.name !== ''
		)
		
		if(onClickNavigateTactics.length){
			onClickNavigateTactics.forEach(function (cfg){
				//selectorDisplayModel = sdm
				var sdm = config.onClick.selectorDisplayModel || "notSet"
				that.onClickNavigateToTactic(cfg, sdm)
			})
		}
		
		let onClickTriggerUnlocks = (config.onClick.unlock || []).filter(matchCurrentUrl)
		if(onClickTriggerUnlocks.length){
			onClickTriggerUnlocks.forEach(function (cfg){
				that.onClickUnlock(cfg)
			})
		}
	}
	
	if (config.embed) {
		let activeEmbedUserData = (config.embed.userData || [])
			.filter(matchCurrentUrl)
		
		
		activeEmbedUserData.forEach(function (cfg){
			that.embedUserData(cfg)
		})
		
		if (activeEmbedUserData.length) {
			that.device.on("variables", function () {
				activeEmbedUserData.forEach(function (cfg){
					that.embedUserData(cfg)
				})	
			})
		}
	}

	if (config.mount) {
		let configMountOne = config.mount.one || []
		var sdm = config.mount.selectorDisplayModel || "notSet"
		configMountOne
			.filter(matchCurrentUrl)
			.forEach(function(cfg) {
				that.mountOne(cfg, sdm)
			})
			
		let configMountCategories = config.mount.categories || []
		configMountCategories
			.filter(matchCurrentUrl)
			.forEach(function(cfg) {
				that.mountCategory(cfg, sdm)
			})
	}
	
}

VocoViewManager.prototype.mountHtmlFragment = async function (params){
	let htmlContent = ''
	
	if(params.html){
		htmlContent = await getHtmlContent(params.html)
	}
	
	if(htmlContent && params.selector){
		let element = document.querySelector(params.selector)
		let htmlFragment = createFragment(htmlContent)
		element.appendChild(htmlFragment)
	}
	
	async function getHtmlContent(url) {
		return await fetch(url)
	    .then((file) => {
	        return file.text()
	    })
	    .catch((error)=> {
	        throw new Error("Error retriving :" + url)
	    })
	}
	
	function createFragment(htmlString) {
	    let fragment = document.createDocumentFragment()
	    let tempDiv = document.createElement('div')
	    tempDiv.innerHTML = htmlString
	    while (tempDiv.firstChild) {
	        fragment.appendChild(tempDiv.firstChild)
	    }
	    return fragment
	}
	
}

VocoViewManager.prototype.embedUserData = function (params) {
	const TEXT_NODE = 3
	let captureTags = /\{\{([^\}]+)\}\}/ig
	let device = this.device

		
	let element = document.querySelector(params.selector)
	
	if(element){
		replaceInElement(element)
	}
	
	function replaceInElement(el) {
		if (el.nodeType == TEXT_NODE) {
			let nodeTemplate = ""
			if (el.thrNodeTemplate) {
				nodeTemplate = el.thrNodeTemplate	
			} else {
				nodeTemplate = el.nodeValue	
			}
			
			if (nodeTemplate.match(captureTags)) {
				if (!el.thrNodeTemplate) {
					el.thrNodeTemplate = el.nodeValue
				}
				el.nodeValue = nodeTemplate.replace(captureTags, function (match, capture){
					return device.evaluateInContext(capture.trim())	
				})
			}
		} else if (el.hasChildNodes()) {
			let child = el.firstChild
			do {
				replaceInElement(child)
			} while (child = child.nextSibling);
		}
	}
}

VocoViewManager.prototype.setupUnlocksForUrl = function (location, unlocksForUrl) {
	let pathname = location.pathname
	let filename = pathname.split("/").pop() || "index.html"
	let device = this.device
	let unlocks = unlocksForUrl.filter(matchCurrentUrl)
	
	let startTime = Date.now()
	let scrollMax = 0
	
	let currentWindow = this.ioc.run("window")
	let storage = this.ioc.run("3r/storage")
	let _ = this.ioc.run("_")
	
	let urlParameters
	
	if (location.search) {
		urlParameters = new URLSearchParams(location.search)
	}
	
	let vocoTesting = storage.getItem("voco-testing")
	let vocoTestingParam
	
	if (urlParameters) {
		vocoTestingParam = urlParameters.get("vocoTesting")
	}
	
	if (typeof vocoTestingParam == "string" && vocoTestingParam) {
		vocoTesting = vocoTestingParam
		storage.setItem("voco-testing", vocoTestingParam)
	} else if (typeof vocoTestingParam == "string" && !vocoTestingParam) {
		storage.removeItem("voco-testing")
		vocoTesting = null
	}
	
	let vocoLanguage = storage.getItem("voco-language")
	let vocoLanguageParam
	
	if (urlParameters) {
		vocoLanguageParam = urlParameters.get("vocoLanguage")
	}
	
	if (typeof vocoLanguageParam == "string" && vocoLanguageParam) {
		vocoLanguage = vocoLanguageParam
		storage.setItem("voco-language", vocoLanguageParam)
	} else if (typeof vocoLanguageParam == "string" && !vocoLanguageParam) {
		storage.removeItem("voco-language")
		vocoLanguage = null
	}
	
	setInterval(unlockOrRemainder, 150)
	device.queueUnlock("WEB.PageView", location.pathname)
	device.queueUnlock("WEB.HostName", location.hostname)
	device.queueUnlock("WEB.UserAgent", _.get(currentWindow, "navigator.userAgent", "").toLowerCase())
	device.queueUnlock('WEB.display.orientation', getScreenOrientation())
	
	function getScreenOrientation() {
		return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
	}

	if (vocoTesting) {
		device.queueUnlock("WEB.vocoTesting", vocoTesting)
		device.on("unlock", function(unlock){
			if(unlock.code){
				console.log('UNLOCK', unlock.code)	
			}
		})
		device.registration.then(function(){
			device.data({"vocoTesting" : vocoTesting})
		})
	}

	if (vocoLanguage) {
		device.language = vocoLanguage
		device.queueUnlock("WEB.vocoLanguage", vocoLanguage)
		device.queueUnlock("field.vocoLanguage", vocoLanguage)
		device.registration.then(function(){
			device.data({"vocoLanguage" : vocoLanguage})
		})
	} else {
		if(device.language || window.navigator.userLanguage || window.navigator.language){
			vocoLanguage = device.language || window.navigator.userLanguage || window.navigator.language
			device.queueUnlock("WEB.vocoLanguage", vocoLanguage)
			device.queueUnlock("field.vocoLanguage", vocoLanguage)
			device.registration.then(function(){
				device.data({"vocoLanguage" : vocoLanguage})
			})
		}
	}
	
	let UTCOffset = new Date().getTimezoneOffset()
	let timezone
	
	if(UTCOffset){
		device.queueUnlock("WEB.time.utcOffset", UTCOffset)
	}
	
	try { 
	    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	}
	catch(err) {
		console.log(err);
	}
	
	if(timezone){
		device.queueUnlock("WEB.time.timeZone", timezone)
	}
	
	return unlockOrRemainder()
		.then(function(res){
			if (res) {
				return res
			} else {
				return device.unlock("WEB.Heartbeat")
			}
		})
	
	function unlockOrRemainder(){
		let scrollBottom = 0
		let dwellTime = Date.now() - startTime
		
		if (typeof window == "object" && typeof window.screenY == "number") {
			scrollBottom = window.scrollY + window.innerHeight
			scrollMax = Math.max(scrollBottom, scrollMax)
		}
		
		let unlockRemainder = unlocks.reduce(function (ur, unlock){
			if (unlock.scroll) {
				let scrollTarget = 0
				if (unlock.scroll.match(/^\d+$/)) {
					scrollTarget = parseInt(unlock.scroll, 10)	
				} else {
					let el = document.querySelector(unlock.scroll)
					
					if (el) {
						scrollTarget = el.offsetTop
					} else {
						ur.remainder.push(unlock)
						return ur
					}
				}
				
				if (scrollMax < scrollTarget) {
					ur.remainder.push(unlock)
					return ur
				}
			}
			
			if (unlock.delay && dwellTime < unlock.delay) {
				ur.remainder.push(unlock)
				return ur
			}
			
			ur.unlock.push(unlock)
			
			return ur
				
		}, { unlock : [], remainder : []})	
		
		
		unlocks = unlockRemainder.remainder
		
		if (unlockRemainder.unlock.length) {
			unlockRemainder.unlock.forEach(function (unlock){
				device.queueUnlock(unlock.code, unlock.packet)
			})
			
			return device.unlock("WEB.Heartbeat")
		} else {
			return Promise.resolve(false)
		}
	}
	
}

VocoViewManager.prototype.initSchemePackageScripts = function (schemeSettings) {
	var self = this
	var schemePackagesList = (schemeSettings.globals.schemePackages || []).filter(function(schemePackage) {
		return schemePackage['_package'] && schemePackage['_package'].main && ( !(schemePackage['_package'].name || "").endsWith("-type") )
	})
	
	var scriptPromisesArray = [ ]
	var packagePromise
	
	for (let i = 0; i < schemePackagesList.length; i++) { 
		let lazyLoading = (schemePackagesList[i].loading || {}).lazy
		if(!lazyLoading){
			packagePromise = self.loadPackageIfNotLoadedYet(schemePackagesList[i])
			scriptPromisesArray.push(packagePromise)
		}
	}
	
	return Promise.allSettled(scriptPromisesArray)
		.then(function () {
			return Promise.all(schemePackagesList.map(function (schemePackage) {
				var packageName = self.helpers.camelCase(schemePackage['_package'].name)
				if (window[packageName] && typeof window[packageName].init == "function") {
					
					let initSchemeSettings = {}
					if (schemeSettings.globals && schemeSettings.globals[packageName]) {
						initSchemeSettings[packageName] = schemeSettings.globals[packageName]
					}
					
					let initPromise = Promise.resolve(window[packageName].init({
							ioc : self.ioc, 
							Device : self.device,
							schemeSettings : initSchemeSettings
					 }))
					 
					self.ioc.define("3r/vvm/init/" + packageName, initPromise)
					return initPromise
				} else {
					return Promise.resolve(false) 
				}
			}))
		})
		.catch(function (err) {
			console.log('One or more promises failed. err :',err);
			return err
		});
}

VocoViewManager.prototype.loadPackageIfNotLoadedYet = function (schemePackage) {
	var self = this
	return self.getSchemePackageName(schemePackage)
		.then(function (packageName) {
			
			if (window[self.helpers.camelCase(packageName)]) {
				return packageName
			} 
			
			return self.loadScript(self.device.schemeId, schemePackage.file)
				.then(function () {
					return packageName
				})
		})
}

function matchCurrentUrl(obj) {
	return matchUrl(window.location, obj)
}

matchCurrentUrl._depends = {
	symbol : "3r/voco-view-manager/matchCurrentUrl",
	modules : [
	]
}

function matchUrl(location, obj) {
	let pathname = location.pathname
	let filename = pathname.split("/").pop() || "index.html"
	let fullUrl = location.toString()
	
	let critUrl = obj.url || ""
	
	/* Blank URL field -> All URLs */
	if (!critUrl) {
		return true
	}
	
	if (critUrl.startsWith("./")) {
		return critUrl.substr(2) == filename
	} else if (critUrl.startsWith("/")) {
		return critUrl == pathname
	} else if (critUrl.startsWith("https://") || critUrl.startsWith("http://")) {
		return critUrl == fullUrl
	} else if (critUrl.startsWith("=/")) {
		let flagStart = critUrl.lastIndexOf("/")
		let flags = critUrl.substring(flagStart)
		let regex = new RegExp(critUrl.substring(2,flagStart), flags)
		
		return fullUrl.match(regex)
	}
}

function matchTacticName(name) {
	var matchFullName = name.match(/\:\:/)
	return function (msg) {
		if (matchFullName) {
			return msg.name == name
		} else {
			return msg.name.split("::")[0] == name
		}
	}
}

function matchTacticCategory(category) {
	return function (msg) {
		if (msg.categories[category]){
			return msg
		}
	}
}

function getGameNavigation(containerElement, targetElement, message, self) {
	function removeMessageToMount() {
		if (self.messageToMount[message.systemId]) {
			self.messageToMount[message.systemId] = self.messageToMount[message.systemId].filter(function(elNav){
				return elNav.el != targetElement
			})
		}
	}
	
	return {
		next : function () { 
			if (containerElement) {
				containerElement.style.display = "none"
			}
			
			removeMessageToMount()
			
			let newTargetElement = targetElement.cloneNode(false)
			targetElement.parentNode.insertBefore(newTargetElement,targetElement)
			targetElement.parentNode.removeChild(targetElement);
			self.device.unlock("WM.next", message.systemId)
			return Promise.resolve(true) 
		}, 
		goTo : function (openId, options = {}, parameters = {}) {
			self.device.unlock("WM.goTo", { systemId : message.systemId, to: openId, options, parameters})
			return self.navigateTo(openId, options, parameters)
		},
		prev : function () { 
			if (containerElement) {
				containerElement.style.display = "none"
			}
			
			removeMessageToMount()
			
			self.device.unlock("WM.prev", message.systemId)
			let newTargetElement = targetElement.cloneNode(false)
			targetElement.parentNode.insertBefore(newTargetElement,targetElement)
			targetElement.parentNode.removeChild(targetElement)
			return Promise.resolve(true) 
		},
		winPrizeDetail: async function (config) {
			if (containerElement) {
				containerElement.style.display = "none"
			}
			self.device.unlock("WM.winPrizeDetail", message.systemId)
			let swalElem = await self.ioc.run('3r/chi/systemAlert')
			window[self.helpers.camelCase(self.winMessageHandler)].default(
				self.moduleFactory(this, self.showMessageToUserFn, config.currentMessage, self.winMessageHandler),
				config,
				swalElem,
				message
			)
			return Promise.resolve(true) 
		},
		multipleWin: async function (config) {
			if (containerElement) {
				containerElement.style.display = "none"
			}
			self.device.unlock("WM.multipleWin", message.systemId)
			let swalElem = await self.ioc.run('3r/chi/systemAlert')
			window[self.helpers.camelCase(self.winMessageHandler)].default(
				self.moduleFactory(this, self.showMessageToUserFn, config.currentMessage, self.winMessageHandler),
				config,
				swalElem,
				message
			)
			return Promise.resolve(true) 
		}
	}
}

function getThreeRadical(message) {
	return ((((message.quantities || {}).link || {}).data || {}).externalPackage || {}).threeRadical
}

function factory(ioc, device, chi,  schemeSettings, modalHelpers, options) {
	let vvmConfig = schemeSettings.globals.vocoViewManager || {}
	 if ((vvmConfig.navigation || {}).nameToLocation && !options.nameToLocation) {
		options.nameToLocation = vvmConfig.navigation.nameToLocation
	}
	
	if (modalHelpers) {
		options.modal = modalHelpers
	}
	
	if (chi.systemMessage) {
		options.showMessageToUserFn = chi.systemMessage
	}
	
	return new VocoViewManager(ioc, device, chi, options)
}

factory._depends = {
	symbol : "3r/voco-view-manager/factory",
	modules : [
		"3r/ioc",
		"3r/Device",
		/^3r\/chi\//,
		"3r/schemeSettings/webapp",
		/^3r\/vvm\/modal\//
	]
}

/*
exports.VocoViewManager
*/
if (typeof exports == "object") {
	exports.VocoViewManager = VocoViewManager
	exports.factory = factory
	exports.matchCurrentUrl = matchCurrentUrl
}

if (typeof ioc == "object") {
	ioc.inject(factory)
	ioc.inject(matchCurrentUrl)
}
