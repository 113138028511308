/*global localStorage, globals, schemeSettings*/
import "regenerator-runtime/runtime"
import "core-js/stable"
import {IoC, ioc} from 'internal-ioc'
import _ from 'lodash'
import modDeviceApi from 'device-api/device'
import modDeviceGetApiAddress from 'device-api/get-api-address'
import modDeviceHelpers from 'device-api/helpers'
import modDeviceAppStats from 'device-api/stats'
import vocoViewManager from './voco-view-manager'
import EventEmitter from 'eventemitter2'


ioc.define("3r/iocId", "global")

let currentScript = document.currentScript;

var scheme = null
var address = ""
var dataset

if (typeof schemeSettings == "object") {
    scheme = schemeSettings.globals['appDetails.app'] 
}

if (currentScript && currentScript.dataset) {
    
    dataset = currentScript.dataset
    
    if (currentScript.dataset.address) {
        address = currentScript.dataset.address
    } else if (typeof currentScript.src == "string") {
        var srcMatches = currentScript.src.match(/^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/)
        
        if (srcMatches && srcMatches[3]) {
            address = srcMatches[3]
        }
    }
    
    if (currentScript.dataset.scheme) {
        scheme = currentScript.dataset.scheme 
    }
}

/*Guess scheme when hosted on downloader */

if (!scheme && (window.location.host.match(/vocohub.com$/) || window.location.host.match(/vfs.cloud9..+.amazonaws.com$/))) {
    scheme = window.location.pathname.split(/\//).filter(Boolean)[0]
}

let queryString = window.location.search
let urlParams = new URLSearchParams(queryString)
let vocoAddress = urlParams.get('__vocoAddress')
if(vocoAddress){
    address = vocoAddress
}

let vocoTesting = localStorage.getItem("voco-testing") || urlParams.get("vocoTesting")
let vocoLanguage = localStorage.getItem("voco-language") || urlParams.get("vocoLanguage")

import chiIoc from 'content-hosting-interface-helpers/chi-ioc'
import chiHelpers from 'content-hosting-interface-helpers/helpers'
import gameDevice from 'content-hosting-interface-helpers/game-device'
import gameRandom from 'content-hosting-interface-helpers/game-random'
import gameThrows from 'content-hosting-interface-helpers/game-throws'
import gameLanguage from 'content-hosting-interface-helpers/game-language'
import gameSubPackage from 'content-hosting-interface-helpers/game-sub-package'

function modulesFactory(ioc, Device, factories, schemeSettings, navigation, showMessageToUserFn, vocoMessage, packageName) {
    var factoryModules = {}
    
    for (var name in factories) {
        if (factories.hasOwnProperty(name)) {
            factoryModules[name] = factories[name](vocoMessage)
        }
    }
    
    factoryModules.device.gameNavigation = navigation
    factoryModules.realDevice = Device
    factoryModules.ioc = ioc
    factoryModules.navigation = navigation
    factoryModules.message = showMessageToUserFn
    factoryModules.schemeSettings = {}
    
    if (packageName && schemeSettings.globals && schemeSettings.globals[packageName]) {
        factoryModules.schemeSettings[packageName] = schemeSettings.globals[packageName];
    }
    
    return factoryModules
}

modulesFactory._depends = {
    symbol : "3r/chi/moduleFactory",
    modules : [
        "3r/ioc",
        "3r/Device",
        /3r\/chi\/factories\//,
        "3r/schemeSettings/webapp"
    ]
}

import modLogin from 'login-api/login'
import modLoginLookup from 'login-api/lookup'
import modLoginHelpers from 'login-api/helpers'
import modLoginRegistrationChecks from 'login-api/registration-checks'
import modMessageHelpers from 'message-api/helpers'
import modMessageContent from 'message-api/content'

export let init = async function (overrides) {
    overrides = overrides || {}
    let schemeId
    let schemeSettings
    let initIoc
    var vvm
    var Device
    
    if (overrides.newIoc) {
        initIoc = new IoC({}, typeof window == "object" ? window : typeof globals == "object" ?  globals : {})
        initIoc.define("3r/ioc", initIoc)
        initIoc.define("3r/InversionOfControl", IoC)
        initIoc.define("3r/iocId", overrides.newIoc)
        if (typeof window == "object") {
            initIoc.define("window", window)
        }
    } else {
        initIoc = ioc
    }
    
    if(!initIoc.isDefined("jQuery")) {
        initIoc.define("jQuery", window.jQuery)
    }
    if(!initIoc.isDefined("Promise")) {
        initIoc.define("Promise", Promise)
    }
    if(!initIoc.isDefined("window")) {
        initIoc.define("window", window)
    }
    if(!initIoc.isDefined("EventEmitter")) {
        initIoc.define("EventEmitter", EventEmitter)
    }
    if(!initIoc.isDefined("_")) {
        initIoc.define("_", _)
    }
    if(!initIoc.isDefined("bootstrap/v4")) {
        initIoc.define("bootstrap/v4", true)
    }
    if(!initIoc.isDefined("3r/storage")) {
        let keysToStore = [
           "device-id" ,
           "getinfo",
           "voco-testing",
           "voco-language"
        ]
        initIoc.define("3r/storage",{
        setItem : function (name, value) {
            if (!name) {
                return
            }
            if (name.endsWith("_thrid") || keysToStore.includes(name)) {
                localStorage.setItem(name, value)
            }
        },
        getItem : function (name) {
            if (name.endsWith("_thrid") || keysToStore.includes(name)) {
                return localStorage.getItem(name)
            }
        },
        removeItem : function (name) {
            if (name.endsWith("_thrid") || keysToStore.includes(name)) {
                return localStorage.removeItem(name)
            }
        },
        clear : function () {
            return localStorage.clear()
        }
    })
    }
    
    if(dataset) {
        initIoc.define("3r/vvm/dataset", currentScript.dataset)
    }  

    if (overrides.address) {
        address = overrides.address
    }

    if (overrides.scheme) {
        scheme = overrides.scheme
    }

    if(address) {
        initIoc.define("3r/appAddress", address)
    }
    
    let resolveSchemeId = initIoc.inject(modResolveSchemeId)
    let getSchemeSettings = initIoc.inject(modGetSchemeSettings)
    
    if (overrides.schemeId) {
        schemeId = overrides.schemeId
    }
    if (overrides.schemeSettings) {
       initIoc.define("3r/schemeSettings/webapp", overrides.schemeSettings)
    } else if (initIoc.isDefined("3r/schemeSettings/webapp")) {
        initIoc.call(["3r/schemeSettings/webapp"], function (iocSettings){
            schemeSettings = iocSettings
            schemeId = schemeSettings.globals.schemeId
        })
    }
    
    if (!schemeSettings && window.schemeSettings) {
        schemeSettings = window.schemeSettings
        initIoc.define("3r/schemeSettings/webapp", schemeSettings)
    }
    
    if (!schemeId && (schemeSettings || {}).globals && schemeSettings.globals.schemeId) {
        schemeId = schemeSettings.globals.schemeId
    }
    
    if (!schemeId) {
        schemeId = await resolveSchemeId(scheme)
    }
    
    window.schemeId = schemeId
    
    if (!schemeSettings) {
        schemeSettings = await getSchemeSettings(schemeId)
        initIoc.define("3r/schemeSettings/webapp", schemeSettings)
    }
    
    let displayURLCheck = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).urlRestrictions || []
    let checkURLPass = false

    //get URL
    var url = window.location.hostname
    var urlParams = window.location.search
    var paramList = new URLSearchParams(urlParams);
    
    if(displayURLCheck.length < 1){
        checkURLPass = true
    } else {
        displayURLCheck.forEach(function(option){
            console.log("OPTION", option)
            if(!option.url || option.url.includes(url) || option.url == ""){
                
                var counter = 0
                var target = option.parameterOptions.parameters.length
                
                option.parameterOptions.parameters.forEach(function(param){
                    console.log("PARAM", param)
                    if(!param.name || (urlParams.includes(param.name.toLowerCase()) && (param.value == paramList.get(param.name.toLowerCase()) || param.value == "")) || param.name == ""){
                        counter ++
                    } else {}
                })
                
                if(counter == target){
                    checkURLPass = true
                }
                
            } else {}
        })
    }
    console.log("Final result", checkURLPass)
    
    if(checkURLPass == false){
        return
    }
    
    initIoc.injectAll(modDeviceHelpers)
    initIoc.injectAll(modDeviceGetApiAddress)
    initIoc.injectAll(modDeviceApi)
    
    let vvmGetPackageName = initIoc.injectWithModules(
    	["Promise","3r/schemeSettings/webapp", "3r/chi/getThreeRadical", "3r/chi/getPackageNameLocal", "3r/chi/getPackageUrl", "3r/chi/getPackageGuid", "3r/Device"],
    	function (Promise, schemeSettings, getThreeRadical, getPackageNameLocal, getPackageUrl, getPackageGuid, Device,  message) {
            var threeRadical = getThreeRadical(message),
                localPackageName = null,
                schemeIdStr = (message.schemeId || Device.schemeId).toString();
            
            if (threeRadical) {
                localPackageName = getPackageNameLocal(message);
                if (localPackageName) {
                    return Promise.resolve(localPackageName);
                } else {
                    return Device.gtp("/external-package/" + schemeIdStr + "/" + getPackageGuid(getPackageUrl(message)) + "/package/name")
                }
            } else {
    	        var vvmConfig = ((schemeSettings || {}).globals || {}).vocoViewManager || {}
                if ((message.category || "").split(" ").indexOf("SPE:Voucher") >= 0) {
                    if ((vvmConfig.settings || {}).voucherMessagePackage) {
                        return Promise.resolve(vvmConfig.settings.voucherMessagePackage)
                    } else {
                        throw new Error("No voucher handler defined")
                    }
                } else if ((message.category || "").split(" ").indexOf("SPE:Competition") >= 0) {
                    if ((vvmConfig.settings || {}).competitionEntryMessagePackage) {
                        return Promise.resolve(vvmConfig.settings.competitionEntryMessagePackage)
                    } else {
                        throw new Error("No Competition handler defined")
                    }
                } else {
                    if ((vvmConfig.settings || {}).simpleMessagePackage) {
                        return Promise.resolve(vvmConfig.settings.simpleMessagePackage)
                    } else {
                        throw new Error("No voucher handler defined")
                    }
                }
            }
    	}
    );
    
    let chiHelpersClone = _.cloneDeep(chiHelpers)
    chiHelpersClone.getPackageName = vvmGetPackageName;
    
    if(!initIoc.isDefined("3r/chi/getPackageName")) {
        initIoc.define("3r/chi/getPackageName", vvmGetPackageName)
    }
    if(!initIoc.isDefined("3r/chi/camelCase")) {
        initIoc.define("3r/chi/camelCase", chiHelpers.camelCase)
    }
    if(!initIoc.isDefined("3r/chi/getThreeRadical")) {
        initIoc.define("3r/chi/getThreeRadical", chiHelpers.getThreeRadical)
    }
    if(!initIoc.isDefined("3r/chi/helpers")) {
        initIoc.define("3r/chi/helpers", chiHelpersClone)
    }
        
    if(!initIoc.isDefined("3r/chi/systemMessage")) {
        initIoc.define("3r/chi/systemMessage", function (messageObj) {
            import('sweetalert2').then(({ default: Swal }) => {
                function createSweetAlertButton(label, buttonAction) {
                    let button = document.createElement('button')
                    button.innerHTML = label
                    button.onclick = buttonAction
                    button.setAttribute('class', 'btn btn-default')
                    button.setAttribute('style', 'margin: 0.5rem')
                    return button
                }
                
                var customHtml = document.createElement('div')
                var textHTML = document.createElement('div')
                textHTML.innerHTML = messageObj.message || ''
                textHTML.setAttribute('class', 'swal2-html-container')
                customHtml.append(textHTML)
                var buttonsArray = messageObj.buttons || []
                
                if(buttonsArray.length > 0){
                    buttonsArray.forEach(function(button) {
                      customHtml.append(createSweetAlertButton(button.caption, button.click))
                    })
                }
                
                var containerClasses = ["voco-instant-message"]
                
                if (messageObj.class) {
                    containerClasses.push(messageObj.class)
                }
                
                Swal.fire({
                    imageUrl: messageObj.image || '',
                    title: messageObj.title || '',
                    timer: messageObj.time,
                    html: customHtml,
                    showConfirmButton: false,
                    showCancelButton: false,
                    customClass : { container : containerClasses.join(" ") }
                })
            })
            .catch(err => {
                console.error(err)
            })    
        })
    
        initIoc.define("3r/chi/systemAlert", (messageObj) => {
            return new Promise((resolve, reject) =>{
                import('sweetalert2').then(({ default: Swal }) => {
                    Swal.fire({
                        showConfirmButton: false,
                        showCancelButton: false,
                        didOpen(element) {
                            return resolve(element)
                        }
                    })
                })
                .catch(err => {
                    console.error(err)
                }) 
            })
        })
    
        initIoc.define("3r/chi/systemAlertClose", (messageObj) => {
            return new Promise((resolve, reject) =>{
                import('sweetalert2').then(({ default: Swal }) => {
                    Swal.close()
                    //When SWAL is fired it adds some class in the html and body tag which is overwrites the original css and the scroll is removed 
                    // in order to tackel that all class is manually removed
                    let htmlClass = document.getElementsByTagName('html')
                    let bodyClass = document.getElementsByTagName('body')
                    
                    if(htmlClass[0].classList.contains('swal2-shown')){
                        htmlClass[0].classList.remove('swal2-shown')
                    }
                    
                    if(bodyClass[0].classList.contains('swal2-shown')){
                        bodyClass[0].classList.remove('swal2-shown')
                    }
                    
                    if(htmlClass[0].classList.contains('swal2-height-auto')){
                        htmlClass[0].classList.remove('swal2-height-auto')
                    }
                    
                    if(bodyClass[0].classList.contains('swal2-height-auto')){
                        bodyClass[0].classList.remove('swal2-height-auto')
                    }
                    
                    //Done to fix div with .swal2-container was showing before other content
                    let swalContainer = document.getElementsByClassName('swal2-container')
                    if(swalContainer[0]){
                        swalContainer[0].style.zIndex = '-1'
                    }
                    
                    resolve(true)
                })
                .catch(err => {
                    console.error(err)
                }) 
            })
        })
    }

    for (let fnName in chiIoc) {
        if (chiIoc.hasOwnProperty(fnName) && ["getPackageName"].indexOf(fnName) < 0 && chiIoc[fnName]._depends) {
            initIoc.inject(chiIoc[fnName]);
        }
    }
    initIoc.injectAll(chiIoc)
    initIoc.inject(gameDevice.factory)
    initIoc.inject(gameRandom.factory)
    initIoc.inject(gameThrows.factory)
    initIoc.inject(gameLanguage.factory)
    initIoc.inject(gameSubPackage.factory)

    initIoc.inject(modulesFactory)
    
    initIoc.injectAll(modLogin)
    initIoc.injectAll(modLoginLookup)
    initIoc.injectAll(modLoginHelpers)
    initIoc.injectAll(modLoginRegistrationChecks)
    initIoc.injectAll(modMessageHelpers)
    initIoc.injectAll(modMessageContent)
    
    let demoMode = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).demoMode || { mode: "none" }
    let isDemoCheckPassed = false
    let disableMode = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).disableMode || { mode: "none" }
    let isDisableCheckPassed = false

    switch (disableMode.mode){
        case "url":
            if (disableMode.parameter && initIoc.run("3r/getParameterByName", disableMode.parameter)){
                isDisableCheckPassed = true
            }
            break;
        case "localStorage":
            if(disableMode.parameter && localStorage.getItem(disableMode.parameter) === "true"){
                isDisableCheckPassed = true
            }   
            break;
        default:
            isDemoCheckPassed = false
            break;
    }
    
    switch(demoMode.mode){
        case "url":
            if(demoMode.parameter && initIoc.run("3r/getParameterByName", demoMode.parameter)){
                isDemoCheckPassed = true
            }
            break;
        case "localStorage":
            if(demoMode.parameter && localStorage.getItem(demoMode.parameter) === "true"){
                isDemoCheckPassed = true
            }   
            break;
        default:
            isDemoCheckPassed = true
            break;
    }
    
    initIoc.inject(modDeviceAppStats.appStatsFactory)
    let appStats = window.appStats = initIoc.run("3r/device-api/stats/appStatsFactory")
    initIoc.define("3r/webframework/appStats", window.appStats)
    
    if(!isDisableCheckPassed){
        if(isDemoCheckPassed){
            importCss(schemeSettings)
            
            await loadExternalScripts((schemeSettings.globals.vocoViewManager || {}).externalScripts || [])
            
            Device = window.Device = initIoc.run(
                "3r/device-api/device/deviceFactory",
                {
                    schemeId : schemeSettings.globals.schemeId,
                    paused : !schemeSettings.globals.unpauseDevice,
                    language : vocoLanguage,
                    forceRefresh : overrides.forceRefresh,
                    disableInfoAutoUpdate : overrides.disableInfoAutoUpdate
                }
            )
            
            Device.language = vocoLanguage
            initIoc.define("3r/Device", window.Device)
            
            var registrationChecks = []
            initIoc.define("3r/registrationChecks", registrationChecks)
            initIoc.call([/3r\/login-api\/registration-checks\//], function (regCheckMods) {
                for (var modName in regCheckMods) {
                    registrationChecks.push(regCheckMods[modName].bind(null))
                }
            })
            function passRegistration(_, registrationChecks) {
                return Promise.all(registrationChecks.map(c => c()))
                    .then(function (results) {
                        return _.every(results, Boolean)
                    })
            }
            passRegistration._depends = {
                symbol : "3r/passRegistration",
                modules : ["_", "3r/registrationChecks"]
            }
            initIoc.inject(passRegistration)
            let unpauseDevice = schemeSettings.globals.unpauseDevice
            let validate = ((schemeSettings.globals || {}).requiredDetails || "") == "None" ? true : undefined
            
            if(!unpauseDevice && validate) {
                let regData = {
                    mode: 'regGM',
                    duid: Device.deviceId,
                    uid: Device.thrid,
                    validate: validate,
                    schemeId: Device.schemeId,
                    appName: Device.schemeId,
                    app: Device.schemeId
                }
                
                const loginLookup = initIoc.run("3r/login-api/lookup/makeLookup", 'login')
                regData = await loginLookup(regData)
                
                let hasEnteredPassword = initIoc.injectWithModules(
                    ['3r/login-api/helpers/requirePassword'],
                    function(requirePassword) {
                        if (requirePassword(schemeSettings)) {
                            return regData.password
                        } else {
                            return true
                        }
                    }
                )
                
                const loginRedirectUrl = initIoc.run("3r/login-api/login/getLookupRedirectUrl", regData)
                
                if (loginRedirectUrl) {
                    window.location = loginRedirectUrl;
                    return;
                }
                
                await initIoc.run("3r/login-api/login/register",
                    regData,
                    '',
                    modLoginHelpers.requirePassword(schemeSettings),
                    hasEnteredPassword
                )
            }
            
            let winMessagePackage = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).winMessagePackage
            let winMessageCHI = null
            let loadingWinMessage = null
            
            if (winMessagePackage)  {
                let pkgConfig = (schemeSettings.globals.schemePackages || []).filter(function (schemePackage){
                    return schemePackage._package && schemePackage._package.name == winMessagePackage
                })[0]
                winMessageCHI = pkgConfig
            }
            
            let simpleMessagePackage = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).simpleMessagePackage
            let simpleMessageCHI = null
            let loadingSimpleMessage = null
            
            if (simpleMessagePackage)  {
                let pkgConfig = (schemeSettings.globals.schemePackages || []).filter(function (schemePackage){
                    return schemePackage._package && schemePackage._package.name == simpleMessagePackage
                })[0]
                simpleMessageCHI = pkgConfig
            }
            
            let voucherMessagePackage = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).voucherMessagePackage
            let voucherMessageCHI = null
            let loadingVoucherMessage = null
            
            if (voucherMessagePackage)  {
                let pkgConfig = (schemeSettings.globals.schemePackages || []).filter(function (schemePackage){
                    return schemePackage._package && schemePackage._package.name == voucherMessagePackage
                })[0]
                voucherMessageCHI = pkgConfig
            }
            
            let competitionEntryMessagePackage = ((schemeSettings.globals.vocoViewManager || {}).settings || {}).competitionEntryMessagePackage
            let competitionEntryMessageCHI = null
            let loadingCompetitionEntryMessage = null
            
            if (competitionEntryMessagePackage)  {
                let pkgConfig = (schemeSettings.globals.schemePackages || []).filter(function (schemePackage){
                    return schemePackage._package && schemePackage._package.name == competitionEntryMessagePackage
                })[0]
                competitionEntryMessageCHI = pkgConfig
            }
            
            var vvmFactory = (initIoc.injectAll(vocoViewManager)).factory
            var remapDomain
            
            if (address && address.startsWith("https://")) {
                remapDomain = address.replace(/^https:\/\//,"").split("/")[0]
            } else if (address && address.startsWith("//")) {
                remapDomain = address.replace(/^\/\//,"").split("/")[0]
            }
            
            vvm = vvmFactory({
                winMessageHandler: winMessageCHI ? winMessagePackage: false,
                simpleMessageHandler : simpleMessageCHI ? simpleMessagePackage : false,
                voucherMessageHandler : voucherMessageCHI ? voucherMessagePackage : false,
                competitionEntryMessageHandler : competitionEntryMessageCHI ? competitionEntryMessagePackage : false,
                remapDomain : remapDomain
            })
        
            initIoc.define("3r/vvm/vvm", vvm)
            
            if (winMessageCHI) {
                loadingWinMessage = vvm.loadScript(schemeSettings.globals.schemeId, winMessageCHI.file)
            }
            
            if (simpleMessageCHI) {
                loadingSimpleMessage = vvm.loadScript(schemeSettings.globals.schemeId, simpleMessageCHI.file)
            }
            
            if (voucherMessageCHI) {
                loadingVoucherMessage = vvm.loadScript(schemeSettings.globals.schemeId, voucherMessageCHI.file)
            }
            
            if (competitionEntryMessageCHI) {
                loadingCompetitionEntryMessage = vvm.loadScript(schemeSettings.globals.schemeId, competitionEntryMessageCHI.file)
            }
            
            let vvmConfig = schemeSettings.globals.vocoViewManager || {}
            
            Device.on("unlock", vvm.unlockNavigateTo.bind(vvm))
            Device.on("unlock", vvm.unlockSetVariable.bind(vvm))
            Device.on("unlock", vvm.unlockInstantMessage.bind(vvm))
            Device.on("unlock", vvm.unlockRedeemMessage.bind(vvm))
            
            if ((vvmConfig.settings || {}).openModalTactics) {
                Device.on("unlock", vvm.unlockOpenModalTactics.bind(vvm))
            }
            
            if(loadingWinMessage){
                await loadingWinMessage
            }
            
            if (loadingSimpleMessage) {
                await loadingSimpleMessage
            }
            
            let tactics = vvm.initSchemePackageScripts(schemeSettings)
            initIoc.define("3r/vvm/initSchemePackages", tactics)
            
            await Device.registration
            
            if (Device.paused) {
                await tactics
            } else {
                await Device.getInfo()
                await tactics
                vvm.mountFromConfig(vvmConfig)
                vvm.setupUnlocksForUrl(window.location, (vvmConfig.unlocks || {}).forUrl || [])
            }
            
            return {
                device : Device,
                vvm : vvm,
                ioc : initIoc,
                globalIoc: ioc
            }
        } else {
            console.warn("Demo Mode checking, Conditions not met to display tactics - Check package configuration")
        }
    } else {
        console.warn("Disable Mode checking, Conditions not met to display tactics - Check package configuration")
    }
        
    async function loadExternalScripts(scripts) {
        return Promise.allSettled(scripts.map(waitAndLoadScript))
    }
    
    async function waitAndLoadScript(script) {
        let result = false
        
        if (script.globalSymbol && typeof window[script.globalSymbol] != "undefined") {
            result = window[script.globalSymbol]
        }
        
        
        if (!result && script.globalSymbol && script.waitTime) {
            result = await waitForScript(script.globalSymbol, script.waitTime)
        }
        
        if (!result && script.url) {
            await loadJs(script.url)
           
            if (script.globalSymbol)  {
                result = window[script.globalSymbol]
            }
        }
        
        if (result && script.globalSymbol) {
            initIoc.define(script.globalSymbol, result)
        }
        
        return result
    }
    
}

async function modResolveSchemeId(storage, schemeName) {
    let schemeId = storage.getItem("voco-scheme-id-" + schemeName) 
    
    if (schemeId && !address.match(/demo-/)) {
        return schemeId
    }
    
    let downloaderConfig = await getJson(address + "/config/" + schemeName)
    schemeId = (((((downloaderConfig || {}).modules || {}).main || {}).replace || {})['app/index.html'] || {}).schemeId
    
    if (!schemeId) {
        throw new Error("Unable to resolve schemeId for: " + schemeName)
    }
    
    localStorage.setItem("voco-scheme-id-" + schemeName, schemeId)
    
    return schemeId 
}

modResolveSchemeId._depends = {
    symbol : "3r/vvm/resolveSchemeId",
    modules : [
        "3r/storage"
    ]
}

async function modGetSchemeSettings(storage, schemeId) {
    let schemeSettingsStr = storage.getItem("voco-scheme-" + schemeId)
    
    if (schemeSettingsStr && !address.match(/demo-/) && !vocoTesting) {
        let schemeSettings = JSON.parse(schemeSettingsStr)

        if (schemeSettings.__vvm_timestamp && Date.now() - schemeSettings.__vvm_timestamp < ((((schemeSettings.globals || {}).vocoViewManager || {}).settings || {}).settingsTimeToLive || 360000) ){
            return schemeSettings 
        }
    }
    
    if(window.location.host.includes("cloud9")){
        var schemeSettings = await getJson("/_/gtp/scheme-settings/flavours/vvm/[" + schemeId + "].webapp?noLoadingImage=true" + (vocoTesting ? "&vocoTesting=" + vocoTesting : ""))
    } else {
        var schemeSettings = await getJson(address + "/_/gtp/scheme-settings/flavours/vvm/[" + schemeId + "].webapp?noLoadingImage=true"  + (vocoTesting ? "&vocoTesting=" + vocoTesting : ""))
    }
    
    
    schemeSettings.__vvm_timestamp = Date.now()
    
    let schemeSettingsString = JSON.stringify(schemeSettings, function (key, value){
        if (typeof value == "string" && !value) {
            return
        }
        
        return value
    })
    
    storage.setItem("voco-scheme-" + schemeId, schemeSettingsString)
    
    return JSON.parse(schemeSettingsString)
}

modGetSchemeSettings._depends = {
    symbol : "3r/vvm/getSchemeSettings",
    modules : [
        "3r/storage"
    ]
}

function getJson(url) {
    /*global jQuery*/ 
    if (typeof window.fetch == "function") {
        return window.fetch(url)
            .then(function (resp){
                if (!resp.ok) {
                    throw new Error("Error fetching:" + url)
                } else {
                    return resp.json()
                }   
            })
    }
    
	return new Promise(function (accept, reject){
		jQuery.ajax({
			dataType : "json",
			url : url
		}).done(function (data){
			return accept(data)
		}).fail(function (e){
			return reject(e)
		})
	})
}

function importCss(schemeSettings) {
    appendTo(document.head, schemeSettings.fonts || '')
    //Set up custom HTML fragments

    var additionalHtml = schemeSettings.html
    if(additionalHtml && additionalHtml.head){
        var headFragments = additionalHtml.head
        Object.keys(headFragments).forEach(key => {
            appendTo(document.head, headFragments[key] || '')
        });
    }
}

function appendTo(el, html){
    let tmpEl = document.createElement("div")
    tmpEl.innerHTML = html
    let node = tmpEl.firstChild
    while(tmpEl.firstChild){
        el.appendChild(tmpEl.firstChild)
    }
}

function loadJs(path)  {
	return new Promise(function (accept, reject){
		let script = document.createElement("script")
		
		script.onload = function () {
			return accept()
		}
		
		script.onerror = function (e) {
				return reject(e)
		}
		
		script.src = path
		document.head.appendChild(script)
	})
}

function waitForScript(globalSymbol, waitTime){ 
    return new Promise(function (accept, reject){
        let start = Date.now()
        let count = 0 
        function testForScript(){
            if (typeof window[globalSymbol] != "undefined") {
               return accept(window[globalSymbol]) 
            } else if (Date.now() - start > waitTime) {
                return accept(false)
            } else {
                count++
                return setTimeout(testForScript,100)
            }
        }
        
        return testForScript()
    })
}

export {ioc as ioc}
