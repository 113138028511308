/*global jQuery*/
/*global currentScript*/

/*Version 0.0.1*/

import { ioc, init } from './common-init'
import vocoModalHtml from './bootstrap-v4-modal.html'

if (typeof currentScript == "object" && currentScript) {
    ioc.define("3r/vvm/dataset", currentScript.dataset)
}

let modalInit = false
let modalOpening = false
let modalClosing = false
let hideByVVM = false

function initModal(iocToUse) {
    if(!iocToUse){
        iocToUse = ioc    
    }
    
    if (!jQuery('#vocoModal').get(0)) {
        let modalSection = document.createElement("section")
        modalSection.className = "voco-modal-container"
        modalSection.innerHTML = vocoModalHtml.trim()
        if(document && document.body){
            document.body.appendChild(modalSection)   
        }
    }

    if (!jQuery("#vocoModal").modal) {
        throw new Error("jQuery.modal not available.")
    }
    jQuery("#vocoModal").modal({ keyboard : false, show : false })

    jQuery("#vocoModal").on("shown.bs.modal", function () {
        modalOpening = false
    })
    jQuery("#vocoModal").on("hidden.bs.modal", function () {
        modalClosing = false
    })
    jQuery("#vocoModal").on("hide.bs.modal", function () {
        if(!hideByVVM) { 
            let device = iocToUse.run("3r/Device")
            let systemId = jQuery(".webframework-external-package-wrapper").attr('sysId')
            device.unlock('WM.modalClosed', systemId)
            hideByVVM = false
        }
        hideByVVM = false
    })
    
    jQuery('#vocoModalClose').click(function() {
        closeModal()
    })
    
    modalInit = true
}

function openModal(message) {
    if (!modalInit) {
        initModal();
    }

    if (modalClosing) {
        jQuery("#vocoModal").one("hidden.bs.modal", function () {
            jQuery("#vocoModal").modal("show")
        })
    } else if (!jQuery(document.body).hasClass("modal-open")) {
        jQuery("#vocoModal").modal("show")
        modalOpening = true 
    }
    
    let modalEl = jQuery("#vocoModal .modal-body").get(0)
    modalEl.innerHTML = ""
    let contentEl = document.createElement("div")
    contentEl.className = "webframework-external-package-wrapper"
    contentEl.setAttribute('sysId', message.systemId)
    modalEl.appendChild(contentEl)
    
    jQuery("#vocoModal .modal-title").text(message.title)
    
    return contentEl
}

function closeModal() {
    modalClosing = true
    if (modalOpening) {
        jQuery("#vocoModal").one("shown.bs.modal", function () {
            hideByVVM = true
            jQuery("#vocoModal").modal("hide")
        })
    } else {
        hideByVVM = true
        jQuery("#vocoModal").modal("hide")
    }
}

ioc.define("3r/vvm/initModal", initModal)
ioc.define("3r/vvm/modal/init", initModal)
ioc.define("3r/vvm/modal/open", openModal)
ioc.define("3r/vvm/modal/close", closeModal)

init().then(function(iocDevice) {
    
    initModal(iocDevice.ioc)
}).catch(function (e){
    console.warn(e, e.stack)
})
